/* eslint-disable react/prefer-stateless-function */
// Allow creation of class component, so that refs can still be used on wrapped components

import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

// Utils
import HocUtil from 'Utils/hoc-util';
import { noOpFn } from 'Utils/no-op-fn';

// Components
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import MeatballItemActions from '../components/meatball-item-actions';
import withT from 'ComponentLibrary/hocs/withT';
import { BUTTON_SETS } from 'Webapp/shared/app/hocs/withItemActions';

function withActionSheet(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        showingActionSheet: false,
      };

      this.toggleActionSheet = this.toggleActionSheet.bind(this);
      this.renderActionSheet = this.renderActionSheet.bind(this);
    }

    toggleActionSheet() {
      this.state.showingActionSheet
        ? this.setState({ showingActionSheet: false })
        : this.setState({ showingActionSheet: true });
    }

    renderActionSheet(actionSheetButtonSet = BUTTON_SETS.DEFAULT_ACTION_SHEET) {
      const { item, section, t } = this.props;

      return (
        <CSSTransition
          in={this.state.showingActionSheet}
          timeout={300}
          classNames="action-sheet-transiton"
          unmountOnExit
        >
          <div className="action-sheet-overlay">
            <div className="action-sheet">
              <MeatballItemActions
                item={item}
                section={section}
                dismiss={this.toggleActionSheet}
                className="action-sheet__actions"
                buttonSet={actionSheetButtonSet}
                isTooltipMenu
              />
              <Button
                name="action-sheet-dismiss"
                className="action-sheet__dismiss"
                styleVariation={StyleVariations.SECONDARY}
                label={t('close')}
                onClick={this.toggleActionSheet}
              />
            </div>
          </div>
        </CSSTransition>
      );
    }

    render() {
      return (
        <Component
          {...this.props}
          toggleActionSheet={this.toggleActionSheet}
          renderActionSheet={this.renderActionSheet}
          showingActionSheet={this.state.showingActionSheet}
        />
      );
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    item: PropTypes.object,
    section: PropTypes.object,
    t: PropTypes.func.isRequired,
  };
  WrappedComponent.defaultProps = {
    section: null,
    onLike: noOpFn,
  };

  return withT(WrappedComponent);
}

export default withActionSheet;
