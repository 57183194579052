import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';
// Utils
import SectionUtil from 'Utils/content/section-util';
import { noOpFn } from 'Utils/no-op-fn';

import { FONTS, BODY_TYPES } from 'Style/typography';
import { SPACING } from 'Style/spacing';
import { TEXT_COLORS } from 'Style/colors';
// Components

// HOCs
import withItemActions, {
  MeatballButton,
} from 'Webapp/shared/app/hocs/withItemActions';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';

const applyIconColorStyles = (color) => ({
  '.flip-icon, .share-icon, .like-icon, .remove-icon, .report-icon, .mute-icon, .less-like-icon, .caret-icon':
    {
      color,
    },
});

const applyVariant = (props) => {
  if (props.itemCardVariant) {
    return {
      [MeatballButton]: {
        marginBottom: '0px',
        cursor: 'pointer',
        padding: `${SPACING.BASE} ${SPACING.MEDIUM} ${SPACING.BASE} 0`,
        color: TEXT_COLORS.primary,
        ...applyIconColorStyles(TEXT_COLORS.primary),
        '&:hover': {
          span: {
            color: TEXT_COLORS.tertiary,
          },
          ...applyIconColorStyles(TEXT_COLORS.tertiary),
        },
      },
    };
  }
  return {};
};

const ActionsList = styled.ul(applyVariant);

const StyledWaistText = styled.div({
  fontFamily: FONTS.FONT_FAKT_FLIPBOARD,
  marginBottom: SPACING.BASE,
  ...BODY_TYPES.XSMALL_STANDARD,
});

function MeatballItemActions({
  item,
  section,
  uid,
  isAuthenticated,
  className,
  generateItemActionButtons,
  itemCardVariant,
}) {
  const classes = classNames(className, {
    'item-actions': true,
  });
  section && uid && SectionUtil.isMagOwner(section, uid);

  const waistText =
    isAuthenticated && section?.isUserPreferenceable && item?.reason?.text;

  const buttons = generateItemActionButtons();

  if (!waistText && buttons.length == 0) {
    return null;
  }
  return (
    <React.Fragment>
      {waistText && <StyledWaistText>{waistText}</StyledWaistText>}
      <ActionsList className={classes} itemCardVariant={itemCardVariant}>
        {buttons.map((b, i) => (
          <li key={`item_action_${i}`} className="item-actions__action">
            {b()}
          </li>
        ))}
      </ActionsList>
    </React.Fragment>
  );
}

MeatballItemActions.propTypes = {
  uid: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool,
  onReport: PropTypes.func.isRequired,
  onRemoveItemFromMagazine: PropTypes.func.isRequired,
  item: PropTypes.object,
  section: PropTypes.object,
  dismiss: PropTypes.func,
  className: PropTypes.string,
  isPhone: PropTypes.bool.isRequired,
  generateItemActionButtons: PropTypes.func.isRequired,
  itemCardVariant: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

MeatballItemActions.defaultProps = {
  item: null,
  section: null,
  className: '',
  itemCardVariant: false,
  dismiss: noOpFn,
};

export default connector(connectAuthentication)(
  withT(withItemActions(MeatballItemActions)),
);
