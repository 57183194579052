import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { noOpFn } from 'Utils/no-op-fn';

// Components
import MeatballsMenuIcon from 'ComponentLibrary/icons/meatballs-menu';
import TooltipMenu from 'ComponentLibrary/menus/tooltip-menu';
import MeatballItemActions from './meatball-item-actions';
import SmartMagazineControls from 'Webapp/shared/app/containers/smart-magazine-controls';
import AnchoredDialog from 'ComponentLibrary/utility/anchored-dialog';

import withItemActions from 'Webapp/shared/app/hocs/withItemActions';
import withActionSheet from 'Webapp/shared/app/hocs/withActionSheet';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectLanguage from 'Webapp/shared/app/connectors/connectLanguage';
import connectIsArticleRoute from 'Webapp/shared/app/connectors/connectIsArticleRoute';
import connectUserState from 'Webapp/shared/app/connectors/connectUserState';

function SocialActions({
  isAmp,
  section,
  smartMagazineSection,
  item,
  toggleActionSheet,
  renderActionSheet,
  isAuthenticated,
  isLargeScreen,
  className,
  alignment,
  isAnalytics,
  hideMeatball,
  generateItemActionButtons,
  actionSheetButtonSet,
  anchoredDialogStyleVariation,
}) {
  if (isAmp) {
    return null;
  }
  const isSmartMagazine = !item && !!smartMagazineSection;
  const classes = classNames(className, {
    'social-actions': true,
  });
  const alignementClasses = classNames({
    'social-actions__content': true,
    'social-actions__content-right': alignment === 'right',
    'social-actions__content-left': alignment === 'left',
  });

  const buttons = generateItemActionButtons();

  const shouldShowOverflowMenu =
    !hideMeatball &&
    !isAnalytics &&
    !isAmp &&
    (isAuthenticated || (section?.isMagazine && !item));

  return (
    <div className={classes}>
      <div className={alignementClasses}>
        {buttons.map((b) => b())}

        {isSmartMagazine ? (
          <SmartMagazineControls
            key="social_actions_smartmagazine"
            section={smartMagazineSection}
            buttonSet={actionSheetButtonSet}
            isTooltipMenu
          />
        ) : (
          shouldShowOverflowMenu && (
            <React.Fragment>
              {isLargeScreen || section?.isTopic ? (
                <TooltipMenu
                  key="social_actions_tooltip"
                  anchoredDialogStyleVariation={anchoredDialogStyleVariation}
                  toggleElement={<MeatballsMenuIcon />}
                >
                  <MeatballItemActions
                    item={item}
                    section={section}
                    buttonSet={actionSheetButtonSet}
                    isTooltipMenu
                    itemCardVariant
                  />
                </TooltipMenu>
              ) : (
                <MeatballsMenuIcon
                  key="social_actions_meatball"
                  onClick={toggleActionSheet}
                />
              )}
              {renderActionSheet(actionSheetButtonSet)}
            </React.Fragment>
          )
        )}
      </div>
    </div>
  );
}

const ACTIONS_ALIGNMENTS = {
  RIGHT: 'right',
  LEFT: 'left',
};

SocialActions.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isAmp: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  section: PropTypes.object,
  smartMagazineSection: PropTypes.object,
  item: PropTypes.object,
  lang: PropTypes.string.isRequired,
  generateItemActionButtons: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleActionSheet: PropTypes.func.isRequired,
  renderActionSheet: PropTypes.func.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  alignment: PropTypes.oneOf(Object.values(ACTIONS_ALIGNMENTS)),
  isAnalytics: PropTypes.bool,
  hideMeatball: PropTypes.bool,
  isUserStateLoading: PropTypes.bool,
  actionSheetButtonSet: PropTypes.array,
  anchoredDialogStyleVariation: PropTypes.oneOf(
    Object.values(AnchoredDialog.styleVariations),
  ),
};

SocialActions.defaultProps = {
  className: '',
  alignment: ACTIONS_ALIGNMENTS.RIGHT,
  toggleActionSheet: noOpFn,
  section: null,
  isAnalytics: false,
  hideMeatball: false,
};

export default connector(
  connectUserState,
  connectIsArticleRoute,
  connectAmp,
  connectAuthentication,
  connectResponsive,
  connectLanguage,
)(withActionSheet(withItemActions(withT(SocialActions))));

export { ACTIONS_ALIGNMENTS };
