import React from 'react';
import PropTypes from 'prop-types';

// Utils
import sentenceCase from 'Utils/content/sentence-case';

// Components
import TooltipMenu from 'ComponentLibrary/menus/tooltip-menu';
import Button, { StyleModifiers } from 'Webapp/shared/app/components/button';
import MeatballsMenuIcon from 'ComponentLibrary/icons/meatballs-menu';
import ConfirmDialog from '../modals/confirm-dialog';

import withT from 'ComponentLibrary/hocs/withT';
import withPersonalize from 'Webapp/shared/app/hocs/withPersonalize';
import withFollow from 'Webapp/shared/app/hocs/withFollow';
import withItemActions from 'Webapp/shared/app/hocs/withItemActions';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

/**
 * TODO: this can now probably be deprecated in favour of deferring to the
 * withItemActions HOC
 */

function SmartMagazineControls({
  section,
  onDelete,
  showModal,
  dismissModal,
  handleUnfollow,
  generateItemActionButtons,
  t,
}) {
  const handleDelete = () => {
    onDelete(section).then(() => handleUnfollow(section));
    dismissModal();
  };

  const buttonStyleModifiers = [
    StyleModifiers.BLOCK,
    StyleModifiers.ALIGN_LEFT,
  ];

  const buttons = generateItemActionButtons();
  return (
    <TooltipMenu toggleElement={<MeatballsMenuIcon />}>
      <ul>
        {buttons.map((b, i) => (
          <li key={`item_action_${i}`} className="item-actions__action">
            {b()}
          </li>
        ))}
        <li>
          <Button
            name="personalized-topic-delete"
            styleModifier={buttonStyleModifiers}
            onClick={() =>
              showModal(ConfirmDialog, {
                name: 'delete-smart-magazine',
                confirmLabel: t('unpersonalize'),
                message: t('confirm_smart_magazine_delete', {
                  smartMagazineTitle: section.title,
                }),
                onConfirm: handleDelete,
              })
            }
          >
            <span>{sentenceCase(t('unpersonalize'))}</span>
          </Button>
        </li>
      </ul>
    </TooltipMenu>
  );
}

SmartMagazineControls.propTypes = {
  section: PropTypes.object.isRequired,
  iconColor: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
  onPersonalize: PropTypes.func.isRequired,
  handleUnfollow: PropTypes.func.isRequired,
  generateItemActionButtons: PropTypes.func.isRequired,
  showFollow: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

SmartMagazineControls.defaultProps = {
  iconColor: undefined,
  showFollow: false,
};

export default connector(connectModal)(
  withItemActions(withFollow(withPersonalize(withT(SmartMagazineControls)))),
);
