import { connect } from 'react-redux';
import {
  deleteSmartMagazine,
  deleteFavorite,
} from '../redux/actions/profile-actions';
import SmartMagazineControls from '../components/smart-magazine-controls';
import { PromiseAll } from 'Utils/promise';

const mapStateToProps = ({ profile }) => ({
  favorites: profile.favorites,
  smartMagazines: profile.smartMagazines,
});

const mapDispatchToProps = (dispatch) => ({
  onDelete: (smartMag) =>
    PromiseAll([
      dispatch(deleteSmartMagazine(smartMag)),
      dispatch(deleteFavorite(smartMag)),
    ]),
});

const SmartMagazineControlsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmartMagazineControls);

export default SmartMagazineControlsContainer;
